import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Hoverimage from "../components/Hoverimage"
import { getImage } from "gatsby-plugin-image"
import PhotoMasonry from "../components/Masonry"
import Heading from "../components/Heading"

const pageQuery = graphql`
  query Digitals {
    allGraphCmsDigital(sort: {fields: order_id, order: DESC}) {
      nodes {
        heading
        order_id
        remoteId
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
export default function Digital() {
  const {
    allGraphCmsDigital: { nodes: projects },
  } = useStaticQuery(pageQuery)
  return (
    <Layout title="Katharina Kohler | Digital" description="Katharina Kohler - M.Sc. psychologist and artist, digital art, Berlin, Germany">
      <Heading>Digital</Heading>
      <div className="flex justify-center">
        <PhotoMasonry>
          {projects.map(({ heading, remoteId, ...project }) => (
              <Hoverimage
                key={remoteId}
                title={heading}
                image={getImage(project.images[0].localFile)}
                to={"/digital/" + remoteId}
                className="mb-4"
              />
          ))}
        </PhotoMasonry>
      </div>
    </Layout>
  )
}
